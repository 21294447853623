import SwalAlert, { ToastType } from "../../services/swalService/SwalService";

const swal = SwalAlert();

const CopyText = (text, field) => {
  navigator.clipboard.writeText(text).then(
    () => {
      swal.toast(`${field} copied to the clipboard.`, ToastType.SUCCESS);
    },
    (err) => {
      swal.toast(`${field} copied to the clipboard.`, ToastType.ERROR);
    }
  );

};
export default CopyText;