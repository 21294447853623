import React from "react";
import PropTypes from 'prop-types';
import Buttons from "../button/Buttons";
import SearchBar from "../../../components/ui/searchBar/SearchBar";

function CardSection({
  children,
  cardTitle,
  rightButton,
  buttonClassName,
  titleButtonClick,
  buttonText,
  searchInput,
  searchInputName,
  textWithIcon,
  iconImg,
  handleChange,
}) {
  return (
    <div
      className={`card ${cardTitle ? "card-section-left" : ""}${
        searchInput && rightButton ? "card-section-between" : ""
      }${rightButton ? "card-button-only" : ""}`}
    >
      {(cardTitle || rightButton) && (
        <div className="card-top-title-btn">
          {cardTitle && <div className="section-title">{cardTitle}</div>}
          <div className="d-flex align-items-center">
            {searchInput && (
              <div className="search-input mr-3">
                <SearchBar
                  searchText={searchInputName}
                  handleChange={handleChange}
                />
              </div>
            )}

            {rightButton && (
              <div className="btn-right-sec">
                {/* Button to open the Add Craft modal */}
                <Buttons
                  buttonTypeClassName={buttonClassName}
                  onClick={titleButtonClick}
                  buttonText={buttonText}
                  textWithIcon={textWithIcon}
                  imagePath={iconImg}
                />
              </div>
            )}
          </div>
        </div>
      )}
      <div className="card-body-sec">{children}</div>
    </div>
  );
}

CardSection.propTypes = {
  // Content to be rendered inside the card section
  children: PropTypes.node,

  // Title displayed at the top of the card
  cardTitle: PropTypes.string,

  // Flag to display a button on the right
  rightButton: PropTypes.bool,

  // Class name for the button styling
  buttonClassName: PropTypes.string,

  // Function to handle button click events
  titleButtonClick: PropTypes.func,

  // Text displayed on the button
  buttonText: PropTypes.string,

  // Flag to display a search input
  searchInput: PropTypes.bool,

  // Name for the search input
  searchInputName: PropTypes.string,

  // Text with icon next to the button
  textWithIcon: PropTypes.string,

  // Image path for the icon next to the button
  iconImg: PropTypes.string,

  // Function to handle search input changes
  handleChange: PropTypes.func,
};

export default CardSection;
