import { GridColumnType } from "../../../components/FinalMolGrid/libs/data/gridColumnType";
import { validationTypes } from "../../../components/forms/libs/data/ValidationTypes";
import { FormFieldTypes } from "../../../components/forms/libs/data/formFieldType";

const initState = {
  app_name: "",
  is_active:false,
  applicationId:""
};
export const ApplicatonFormData = {
  name: "Application Name",
  initialState: initState,
  section: [
    {
      title: "Appliaction Management",
      row: {},
      style: {
        sectionStyle: "row mb-3",
      },
      fields: [
        {
          id: "app_name",
          label: "Application Name",
          Field_Name: "Application Name",
          fieldType: FormFieldTypes.INPUT,
          dataField: "app_name",
          fieldSetting: {
            placeholder: "Please Enter Application Name",
          },
          validation: [{ type: validationTypes.REQUIRE }],
          style: {
            containerCss: "col-md-12 mt-3"
          }
        },
        {
          id: "is_active",
          label: "Is Active",
          Field_Name: "Is Active",
          fieldType: FormFieldTypes.CHECKBOX,
          dataField: "is_active",
          fieldSetting: {
            allowSpace: true,
          },
          validation: [{ type: validationTypes.REQUIRE }],
          style: {
            containerCss:
              "col-lg-4 col-md-3 validation-left-0 align-item-center justify-content-center",
          },
        },
      ],

    }
  ]


}
export const ApplicationManagementConfig = {
  columns: [
    {
      name: "Application Name",
      fieldName: "app_name",
      colStyle: {
        width: "60%",
      },
      //allowShort: true,
    },
    {
      name: "Is Active",
      fieldName: "is_active",
      colStyle: {
        width: "25%",
      },
      colType: GridColumnType.CHECKBOX,
      colSettings: {
        allowCheckbox: true,
        isDisabled: false,
      },
    },

    {
      name: "Action",
      colStyle: {
        width: "15%",
      },
      colType: GridColumnType.ACTION,
      defaultAction: {
        allowEdit: true,
        allowDelete: true,

      },
    },
  ],

};