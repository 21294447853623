import React from "react";
import "./SearchBar.scss";
import PropTypes from "prop-types";
import Buttons from "../button/Buttons";
import Input from "../../forms/ui/inputs/input/Input";
import Iconify from "../iconify/iconify";

function SearchBar(props) {
  return (
    <React.Fragment>
      <div className="search-section">
        <div className="search-part">
          <Input
            name="search Value"
            type="text"
            value={props.searchValue}
            placeholder={props.searchText}
            onChange={props.handleChange}
            onKeyPress={props.handleKeyPress}
          />
          <div className="search-btn">
            <Iconify icon="ic:outline-search" className="open-bar" />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

SearchBar.propTypes = {
  searchValue: PropTypes.string,
  searchText: PropTypes.string,
  handleChange: PropTypes.func,
};

export default SearchBar;
