import React from 'react';

import AppRoutes from './assets/layouts/navigation/AppRoutes'
import { ComponentNavigation } from './assets/layouts/navigation/ComponentNavigation'

const App = () => {
  return (
    <div>
      <AppRoutes componentRoutes={ComponentNavigation} />
    </div>
  )
}

export default App
