import { createSlice } from "@reduxjs/toolkit";
import { getAuthProps, setAuthProps, signOut } from "../../lib/authenticationLibrary";

const authData = getAuthProps();

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isAuthenticated: (authData != null),
    user: (authData?.user), //Set user data from cookie.
    token: (authData?.token), //Set token data from cookie.
    roles: (authData?.roles),
    authData,
    isLogedin: false
  },
  reducers: {
    authentication: (state, action) => {
      const { full_name, user_id, user_name, is_active, is_authenticated, ...newAuthProps } = action.payload;
      let userObj = {
        full_name, user_id, user_name, is_active
      }
      let request = {
        ...newAuthProps,
        user: userObj
      }
      setAuthProps(request);
      state.isLogedin = true;
      state.user = userObj;
    },
    logout: (state) => {
      state.isLogedin = false;
      signOut();
    }
  }
});


export const { authentication, logout } = authSlice.actions;
export default authSlice.reducer;