import { createApi } from '@reduxjs/toolkit/query/react';
import { transformErrorResponse, transformSuccessResponse, transformSucessResponse } from '../../utils/API/responseMiddleware';
import { defaultBaseQuery } from '../../utils/API/fetchBaseQuery';
import { transformRequest } from '../../utils/API/requestMiddleware';

const authapi = createApi({
    baseQuery: defaultBaseQuery,
    endpoints: (builder) => ({
        userLogin: builder.mutation({
            query: (credentials) => ({
                url: '/authLogin/',
                method: 'POST',
                body: transformRequest(credentials)
            }),
            invalidatesTags: ['authentication'],
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse
        }),
    }),
});

export const { useUserLoginMutation } = authapi;
export default authapi;
