/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";
import FormCreator from "../../../components/forms/FormCreator";
import { ApplicatonFormData } from "../config/ApplicationManagement.data";
import Buttons from "../../../components/ui/button/Buttons";
import {
  useCreateApplicationMutation,
  useLazyGetApplicationByApplicationIdQuery,
  useUpdateApplicationMutation,
} from "../../../app/services/applicationManagementAPI";
import { removeFormFields } from "../../../utils/FormFields/RemoveFields/handleRemoveFields";
import SwalAlert, { ToastType } from "../../../services/swalService/SwalService";



const AddEditApplicationManagement = ({
  onClose,
  applicationId,
  onSuccess,
  isEdit,
  isModelOpen,
  applicationData,
  setApplicationData
}) => {
  const formRef = useRef();
  const swal = SwalAlert();
 // const [applicationData, setApplicationData] = useState(ApplicatonFormData);
  const [createApplication,{  isLoading: isAddApplicationLoading,isSuccess: isAddApplicationSuccess, data: allAddApplicationData, }] = useCreateApplicationMutation();
  const [updateApplication,{isLoading: isUpdateApplicationLoading,isSuccess: isUpdateApplicationSuccess,data: allUpdateApplicationData, } ] = useUpdateApplicationMutation();
  const [
    getApplicationByApplicationId,
    {
      isFetching: isGetApplicationByApplicationIdFetching,
      isSuccess: isGetApplicationByApplicationIdSuccess,
      data: allGetApplicationByApplicationIdData,
    },
  ] = useLazyGetApplicationByApplicationIdQuery();

  useEffect(() => {
   
      let updatedFormData;
      updatedFormData = removeFormFields(applicationData, ["is_active"]);
      setApplicationData(updatedFormData);
      if(isEdit){
      setApplicationData(ApplicatonFormData);
      getApplicationByApplicationId(applicationId);
    }
  }, [isEdit,isModelOpen]);

  useEffect(() => {
    if (isAddApplicationSuccess && allAddApplicationData) {
      swal.toast(allAddApplicationData.message, ToastType.SUCCESS);
      onSuccess();
      onClose();
    }
  }, [isAddApplicationSuccess, allAddApplicationData]);

  useEffect(() => {
    if (isUpdateApplicationSuccess && allUpdateApplicationData) {

      swal.toast(allUpdateApplicationData.message, ToastType.SUCCESS);
      onSuccess();
      onClose();
    }
  }, [isUpdateApplicationSuccess, allUpdateApplicationData]);

  useEffect(() => {
    if (
      !isGetApplicationByApplicationIdFetching &&
      isGetApplicationByApplicationIdSuccess &&
      allGetApplicationByApplicationIdData
    ) {
      const modifyData = { ...applicationData };
      let request = {
        application_id:
          allGetApplicationByApplicationIdData.data.application_id,
        app_name: allGetApplicationByApplicationIdData.data.app_name,
        is_active: allGetApplicationByApplicationIdData.data.is_active,
      };
      modifyData.initialState = request;
      setApplicationData(modifyData);
    }
  }, [
    isGetApplicationByApplicationIdFetching,
    isGetApplicationByApplicationIdSuccess,
    allGetApplicationByApplicationIdData,
  ]);

  const handleAddEditApplication = () => {

    const applicationFormData = formRef.current.getFormData();
    if (applicationFormData && !applicationId) {
      const request = {
        ...applicationFormData,
      };
      createApplication(request);
    } else if (applicationFormData && applicationId) {
      const request = {
        ...applicationFormData,
        application_id: applicationId,
        app_name: applicationFormData?.app_name,
        is_active: applicationFormData?.is_active,
      };
      updateApplication(request);
    }
  };
  return (
    <>
      <div>
        <FormCreator
          ref={formRef}
          config={applicationData}
          {...applicationData}
        />
      </div>
      <div className="col-md-12 mt-2">
        <div className="d-flex align-item-center justify-content-end">
          <Buttons
            buttonTypeClassName="theme-button"
            onClick={handleAddEditApplication}
            buttonText={`${isEdit ? "Update" : "Add"}`}
            isLoading={isAddApplicationLoading || isUpdateApplicationLoading}
          />
          <Buttons
            buttonTypeClassName="dark-btn ml-5"
            buttonText="Cancel"
            onClick={onClose}
          />
        </div>
      </div>
    </>
  );
};
export default AddEditApplicationManagement;
