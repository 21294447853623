import { createApi } from '@reduxjs/toolkit/query/react';
import { transformErrorResponse, transformSuccessResponse } from '../../utils/API/responseMiddleware';
import { customFetchBase } from '../../utils/API/fetchBaseQuery';
import { encryptQueryString, transformRequest } from '../../utils/API/requestMiddleware';

const bucketsAPI = createApi({
    baseQuery: customFetchBase,
    endpoints: (builder) => ({

        getAllApplicationList: builder.query({
            query: () => ({
                url: '/application/getAllApplicationList/',
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        getBucketList: builder.mutation({
            query: (requestData) => ({
                url: '/bucket/getBucketList/',
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        createBucket: builder.mutation({
            query: (requestData) => ({
                url: '/bucket/createBucket/',
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        updateBucket: builder.mutation({
            query: (requestData) => ({
                url: '/bucket/updateBucket/',
                method: 'PUT',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        getBucket: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/bucket/getBucket/?bucket_id=${Number(id)}`),
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        deleteBucket: builder.mutation({
            query: (id) => ({
                url: encryptQueryString(`/bucket/deleteBucket/?bucket_id=${id}`),
                method: 'DELETE',
                body: transformRequest(id)
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        getFilesByBucketId: builder.mutation({
            query: (requestData) => ({
                url: '/file/getFilesByBucketId/',
                method: 'POST',
                body: transformRequest(requestData)
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        downloadFile: builder.query({
            query: (data) => ({
                url: encryptQueryString(`/file/downloadFile/?bucket_uuid=${data.bucket_uuid}&file_uuid_name=${data.file_uuid_name}`),
                method: 'GET',
                responseHandler: (response) => response.blob()
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse
        }),

    }),
});

export const { useGetBucketListMutation, useCreateBucketMutation,
    useLazyGetBucketQuery, useUpdateBucketMutation, useDeleteBucketMutation, useLazyGetAllApplicationListQuery,useGetFilesByBucketIdMutation,useLazyDownloadFileQuery
} = bucketsAPI;
export default bucketsAPI;
