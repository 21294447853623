import { GridColumnType } from "../../../components/FinalMolGrid/libs/data/gridColumnType";

export const bucketFileConfig = {
    columns: [
        {
            name: "File name",
            fieldName: "actual_file_name",
            allowShort: false,
            colStyle: {
                width: "40%",
                textAlign: "center"
            }
        },
  
        {
            name: "Created Date",
            fieldName: "created_at",
            colType: GridColumnType.DATE,
            allowShort: false,
            colStyle: {
                width: "30%",
                textAlign: "center"
            },
            colSettings: {
                format: "MM/DD/YYYY"
            }
        },
        {
            name: "Action",
            colType: GridColumnType.ACTION,
            defaultAction: {
                allowEdit: false,
                allowDelete: false,
            },
            customAction: [
                {
                  name: "DOWNLOAD",
                  iconName: "material-symbols:download",
                  title: "Download",
                  className: "download-icon"
                },
            ],
            allowShort: false
        },
    ],
    allowEdit: false,
    hasChildGridTable: false,
    gridStyle: {
        tableClass: "border-table-simple"
    }
}