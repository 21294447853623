
import { encryptQueryString, transformRequest } from "../../utils/API/requestMiddleware";
import { transformErrorResponse, transformSuccessResponse } from "../../utils/API/responseMiddleware";
import { customFetchBase } from "../../utils/API/fetchBaseQuery";
import { createApi } from "@reduxjs/toolkit/query/react";

//const token="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiIzIiwibmJmIjoxNzI3Nzg0MjU5LCJleHAiOjE3Mjk1MTIyNTksImlhdCI6MTcyNzc4NDI1OSwiaXNzIjoiaHR0cHM6Ly9sb2NhbGhvc3Q6NDQzMTgifQ.vjRruVEAjFXrvnfEEZF6JS47LnHFgVyHuydiGPU0CFo";
const applicationManagementAPI = createApi({
    reducerPath: "applicationManagementAPI",
    baseQuery: customFetchBase,
    endpoints: (builder) => ({

        createApplication: builder.mutation({
            query: (Details) => ({
                url: '/application/createApplication/',
                method: 'POST',
                body: transformRequest(Details)
            }),

            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse
        }),
        getApplicationByApplicationId: builder.query({
            query: (application_id) => ({
                url: encryptQueryString(`/application/getApplication/?application_id=${Number(application_id)}`),
                Method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse

        }),
     

        getAllApplicationList: builder.mutation({
            query: (Details) => ({
                url: '/application/getApplicationList/',
                method: 'POST',
                body: transformRequest(Details)
            }),

            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse
        }),
      
        updateApplication: builder.mutation({
            query: (request) => ({
                url: encryptQueryString(`/application/updateApplication/?application_id=${Number(request.application_id)}`),
                method: 'PUT',
                body: transformRequest(request)
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        deleteApplication: builder.mutation({
            query: (application_id) => ({
                url: encryptQueryString(`/application/deleteApplication/?application_id=${application_id}`),
                method: 'DELETE',
                body: transformRequest(application_id)
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse
        }),

    })
})
export const {
    useCreateApplicationMutation,
    useLazyGetApplicationByApplicationIdQuery,
    useGetAllApplicationListMutation,
    useUpdateApplicationMutation,
    useDeleteApplicationMutation,

} = applicationManagementAPI

export default applicationManagementAPI;