// Menus & Header Images

// Others
import calender from "../assets/images/calender.png";
import logo from "../assets/images/logo.png";
import loginBG from "../assets/images/login-bg.png";
import noRecordFound from "../assets/images/NoData.svg";
import PlusIcon from "../assets/images/PlusIcon.png";
import eyeIcon from "../assets/images/eye.png";
import eyeSlashIcon from "../assets/images/eyeslash.png";

export const AppIcons = {

  // Menus & Header Images

  // Others
  calenderIcon: calender,
  logoImage: logo,
  LoginBG: loginBG,
  noRecordImg: noRecordFound,
  PlusIcon: PlusIcon,
  EYEICON: eyeIcon,
  EYESLASHICON: eyeSlashIcon,
};
