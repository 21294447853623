import { decryptAES } from "../../services/CryptoService";
import SwalAlert, { ToastType } from "../../services/swalService/SwalService";

const swal = SwalAlert();

export const transformSuccessResponse = async (response, meta, arg) => {

    let resData = response.data;
    let rData = response;
    let apiData = rData;

    if (response.isEnType) {
        resData = decryptAES(resData);
        return resData;
    }

    if (meta.request.url.includes('downloadFile') && arg?.file_uuid_name) {
        resData = response; // Decrypt the data.
        apiData = rData;
    }

    if (response.status_code === 200) {
        return response;
    }
    else if (meta.request.url.includes('downloadFile') && meta.response.status === 200 && arg.file_uuid_name) {
        const responseData = {
            fileName: arg.file_uuid_name,
            fileData: apiData
        }
        return responseData;
    }
    else {
        swal.toast(response.message ? response.message : "Something went wrong with API. Please contact admin!!", ToastType.ERROR);
        return null;
    }

}

// export const transformSuccessResponse = async (response, meta, arg) => {
//     let resData = response.responseData;
//     let apiData

//     if (response.isEnType) {
//         resData = decryptAES(resData);
//         apiData = resData;
//     }
//     else if (meta.request.url.includes('Export')) {
//         return response
//     }
//     else {
//         apiData = JSON.parse(resData);
//     }

//     if (apiData.statusCode === 200) {
//         return apiData.data;
//     }
//     else {
//         error(apiData.message ? apiData.message : "Something went wrong with API. Please contact admin!!");
//         return null;
//     }
// }

export const transformErrorResponse = (response, meta, args) => {
    // ------> FETCH_ERROR 
    if (response.status && response.status === "FETCH_ERROR") {
        swal.toast("Oops! error while fetching data from the server. Please try again later.", ToastType.ERROR);
        return null;
    }

    // ------> PARSING_ERROR & STATUS:500
    if (response.status && response.status === "PARSING_ERROR" && response.originalStatus === 500) {
        const errorMessage = response.data.toLowerCase();
        if (errorMessage.includes("sql server") ||
            errorMessage.includes("connection") ||
            errorMessage.includes("provider")) {
            swal.toast("Oops! There was an issue with the SQL Server or database connection. Please try again later and ensure that the SQL Server is configured correctly.", ToastType.ERROR);
            return null;

        } else {
            swal.toast("Oops! There was an issue processing the server's response. Please try again later.", ToastType.ERROR);
            return null;
        }
    }

    if (response.status && response.data.responseData) {
        let resData = response.data.responseData;

        //  check if the response is encrypted
        if (response.data.isEnType) {
            resData = decryptAES(resData);
        } else {
            resData = JSON.parse(resData);
        }

        if (response.status === 400) {

            if (resData.errors) {
                let errorMessage = `Validation Error: ${resData.message}`;

                // iterate through the errorrs and append them to the error message
                for (const fieldName in resData.errors) {
                    if (resData.errors.hasOwnProperty(fieldName)) {
                        errorMessage = `\n ${fieldName} : ${resData.errors[fieldName].join("\n")} `
                    }
                }
                swal.toast(errorMessage, ToastType.ERROR);
            }
        }
        else if (response.status === 500) {
            let errorMessage = "something wrong with API call!! Please contact admin";
            swal.toast(errorMessage, ToastType.ERROR);
        }

    }

}