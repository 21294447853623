import React, { useState } from "react";
import PropTypes from "prop-types";
import "./Avatar.scss";

const Avatar = ({ src, alt, size, shape, bgcolor, gradient }) => {
  const [imgError, setImgError] = useState(false);

  // Set the background style based on whether a gradient or a solid color is provided
  const style = gradient ? { background: gradient } : bgcolor ? { backgroundColor: bgcolor } : {};

  return (
    <>
      {imgError || !src ? (
        <div
          className={`avatar alt-avatar ${size} ${shape}`}
          style={style}
          title={alt}
        >
          {alt ? getInitials(alt):"U"}
        </div>
      ) : (
        <img
          src={src}
          alt={alt}
          className={`avatar ${size} ${shape}`}
          onError={() => setImgError(true)}
        />
      )}
    </>
  );
};

const getInitials = (str) => {
  const words = str
    .split(' ')  // Split the string into words
    .filter(word => word.length > 0);  // Filter out any empty strings

  let initials = words.slice(0, 2)  // Take only the first two words
    .map(word => word[0].toUpperCase())  // Get the first character of each word and convert to uppercase
    .join('');  // Join the characters together

  return initials;
};


Avatar.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  shape: PropTypes.oneOf(["circle", "square"]),
  bgcolor: PropTypes.string,
  gradient: PropTypes.string, // Add gradient prop type
};

Avatar.defaultProps = {
  alt: "U",
  size: "medium",
  shape: "circle",
};

export default Avatar;
