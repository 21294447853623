import React, { useState } from "react";
import Sidebar from "./components/sidebar/Sidebar";
import { Outlet } from "react-router-dom";
import Header from "./components/header/Header";

const Layout = (props) => {
  // Get the current location using React Router's useLocation hook
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  const toggleSidebarCollapsed = () => {
    setSidebarCollapsed((prevState) => !prevState);
  };

  return (
    <div className={`main-page-layout`}>
      <div className={`middle-page-section`}>
        <div
          className={`center-content-part ${
            sidebarCollapsed ? "collapsed" : ""
          }`}
        >
          <Sidebar
            componentRoutes={props.componentRoutes}
            sidebarCollapsed={sidebarCollapsed}
            toggleSidebarCollapsed={toggleSidebarCollapsed}
          />
          <div className="right-sec-part">
            <Header />
            <div className="right-desc-part">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
