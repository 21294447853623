import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "../../layouts/Layout";
import NoRecordFound from "../../../components/FinalMolGrid/ui/noRecordFound/NoRecordFound";
import PageLoader from "../../../components/ui/pageLoader/PageLoader";
import ProtectedRoute from "../Routes/ProtectedRoute";


const Login = React.lazy(() => import("../../../Pages/login/Login"));

const AppRoutes = (props) => {
  return (
    <Router>
      <Suspense fallback={<PageLoader />}>
        <Routes>
          <Route name="Layout" path="/"
            element={
              <ProtectedRoute>
                <Layout componentRoutes={props.componentRoutes} />
              </ProtectedRoute>
            }>
            {props.componentRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                name={"test"}
                element={< route.component securityKey={route.securityKey} />}
              />
            ))}
          </Route>
          <Route exact name="Login" path="/Login" element={<Login />} />
          <Route path="*" element={<NoRecordFound />} />
        </Routes>
      </Suspense>
    </Router>
  );
};
export default AppRoutes;
