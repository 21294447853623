/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useImperativeHandle, useRef, useState } from "react";
import FinalMolGrid from "../../../components/FinalMolGrid/FinalMolGrid";
import { ApplicationManagementConfig } from "../config/ApplicationManagement.data";
import {
  useDeleteApplicationMutation,
  useGetAllApplicationListMutation,
} from "../../../app/services/applicationManagementAPI";
import SwalAlert, { ToastType } from "../../../services/swalService/SwalService";

const ApplicationManagementList = ({ handleEditClick, childRef,search }) => {
  const molGridRef = useRef();
  const [appList, setAppList] = useState([]);
  const swal = SwalAlert();
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [
    getAllApplicationList,
    {
      isLoading: isGetApplicationListLoading,
      isSuccess: isGetApplicationListSuccess,
      data: isGetApplicationListData,
    },
  ] = useGetAllApplicationListMutation();
  const [
    deleteApplication,
    { isSuccess: isDeleteApplicationSuccess, data: isDeleteApplicationData },
  ] = useDeleteApplicationMutation();

  useEffect(() => {
    onGetData();
  }, [search]);

  
  useEffect(() => {
    if (isDeleteApplicationSuccess && isDeleteApplicationData) {
      swal.toast(isDeleteApplicationData.message, ToastType.SUCCESS);
      onGetData();
    }
  }, [isDeleteApplicationSuccess, isDeleteApplicationData]);

  const handleDeleteClick = (data) => {
    swal.confirm(
      "Delete?",
      "Are you sure you want to Delete?",
      "Delete",
      "Cancel"
    ).then((confirmed) => {
      if (confirmed) {
        deleteApplication(data.application_id);
      }
    });
  };

  useEffect(() => {
    if (
      isGetApplicationListSuccess &&
      isGetApplicationListData
    ) {
      
      if (isGetApplicationListData) {
        setAppList(isGetApplicationListData.data.data_source);
      }
      if (isGetApplicationListData.total_count) {
        setTotalRowCount(isGetApplicationListData.data.total_count);
      }
    }
  }, [

    isGetApplicationListSuccess,
    isGetApplicationListData,
  ]);


  const onGetData = () => {

    if (molGridRef.current) {
      const defaultPageObject = molGridRef.current.getCurrentPageObject();
      getLists(defaultPageObject);
    }
  }

  const handlePageChange = (page) => {
    getLists(page);
  };

  const getLists = (pageObject) => {

    const request = {
      pagination: {
        page_number: pageObject.pageNumber,
        page_size: pageObject.pageSize,
      },
      filters: { search_text:search },
      sort_string: "",
    };
    getAllApplicationList(request);
  };
  const actionHandler = {
    EDIT: handleEditClick,
    DELETE: handleDeleteClick,
  };
  const onGetApplication = () => {
    onGetData();
  };
  useImperativeHandle(childRef, () => ({
    callChildFunction: onGetApplication,
  }));
  return (
    <>
      <FinalMolGrid
        ref={molGridRef}
        configuration={ApplicationManagementConfig}
        dataSource={appList}
      
        allowPagination={true}
        onActionChange={actionHandler}
        isLoading={isGetApplicationListLoading}
        pagination={{
          totalCount: totalRowCount,
          pageSize: 10,
          currentPage: 1,
        }}
        onPageChange={handlePageChange}
      />
    </>
  );
};
export default ApplicationManagementList;
