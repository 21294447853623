import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import applicationManagementAPI from "./services/applicationManagementAPI";
import authapi from "./services/authAPI";
import bucketsAPI from "./services/bucketsAPI";
import authReducer from "./slice/authSlice";

export const store = configureStore({
    reducer: {
        auth: authReducer,
        [applicationManagementAPI.reducerPath]: applicationManagementAPI.reducer,
        [authapi.reducerPath]: authapi.reducer,
        [bucketsAPI.reducerPath]: bucketsAPI.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
        applicationManagementAPI.middleware,
        authapi.middleware,
        bucketsAPI.middleware,
    ),
})
setupListeners(store.dispatch);

