/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useGetFilesByBucketIdMutation, useLazyDownloadFileQuery, useLazyGetBucketQuery } from "../../../app/services/bucketsAPI";
import { useLocation, useParams } from "react-router-dom";
import { decryptUrlData } from "../../../services/CryptoService";
import Iconify from "../../../components/ui/iconify/iconify";
import "./DetailCard.scss";
import CopyText from "../../../utils/CopyText/CopyText";
import FinalMolGrid from "../../../components/FinalMolGrid/FinalMolGrid";
import { bucketFileConfig } from "../config/bucketFile.Data";
import formatDate from "../../../components/FinalMolGrid/libs/formatDate";
const BucketManagementDetail = () => {
    const molGridRef = useRef();
    const { id, } = useParams();
    const bucketId = id ? decryptUrlData(id) : 0;
    const location = useLocation();
    const data = location.state || {};

    const [bucketData, setBucketData] = useState([]);
    const [bucketFileList, setBucketFileList] = useState([]);
    const [downloadFileName, setDownloadFileName] = useState(null);

    const [totalRowCount, setTotalRowCount] = useState(0);
    const [getBucket, { isFetching: isGetBucketFetching, isSuccess: isGetBucketSuccess, data: isGetBucketData }] = useLazyGetBucketQuery();
    const [getFilesByBucketId, { isLoading: isGetFilesByBucketIdLoading, isSuccess: isGetFilesByBucketIdSuccess, data: isGetFilesByBucketIdData }] = useGetFilesByBucketIdMutation();

    const [downloadFile, { isFetching: isDownloadFileFetching, isSuccess: isDownloadFileSuccess, data: isDownloadFileData }] = useLazyDownloadFileQuery();

    useEffect(() => {

        getBucket(bucketId);
        onGetData();
    }, [bucketId]);

    const onGetData = () => {

        if (molGridRef.current) {
            const defaultPageObject = molGridRef.current.getCurrentPageObject();
            getLists(defaultPageObject);
        }
    }

    const handlePageChange = (page) => {
        getLists(page);
    };

    const getLists = (pageObject) => {

        const request = {
            pagination: {
                page_number: pageObject.pageNumber,
                page_size: pageObject.pageSize,
            },
            filters: { search_text: "" },
            sort_string: "",
            bucket_uuid: data.bucket_uuid,
        };
        getFilesByBucketId(request);
    };

    useEffect(() => {
        if (!isGetBucketFetching && isGetBucketSuccess && isGetBucketData) {

            setBucketData(isGetBucketData.data);
        }
    }, [isGetBucketFetching, isGetBucketSuccess, isGetBucketData]);

    useEffect(() => {

        if (isGetFilesByBucketIdSuccess && isGetFilesByBucketIdData) {

            if (isGetFilesByBucketIdData) {
                setBucketFileList(isGetFilesByBucketIdData.data.data_source);
            }
            if (isGetFilesByBucketIdData.total_count) {
                setTotalRowCount(isGetFilesByBucketIdData.data.total_count);
            }

        }
    }, [isGetFilesByBucketIdSuccess, isGetFilesByBucketIdData]);

    const handleDownload = (dataObj) => {

        const request = {
            bucket_uuid: data.bucket_uuid,
            file_uuid_name: dataObj.file_uuid_name
        }
        setDownloadFileName(dataObj.actual_file_name)
        downloadFile(request);
    }
    const actionHandler = {
        DOWNLOAD: handleDownload,
    }


    useEffect(() => {

        if (!isDownloadFileFetching && isDownloadFileSuccess && isDownloadFileData) {

            const fileData = isDownloadFileData.fileData;
            const blob = new Blob([fileData], { type: fileData.type });
            const fileURL = URL.createObjectURL(blob);


            const link = document.createElement("a");
            link.href = fileURL;
            link.download = downloadFileName;
            document.body.appendChild(link);
            link.click();
            link.remove();
            URL.revokeObjectURL(fileURL);

        }
    }, [isDownloadFileFetching, isDownloadFileSuccess, isDownloadFileData]);


    return (<>
        <div className="detail-card">
            <div className="row">
                <div className="col-md-4">
                    <div className="key-value-pair mb-2">
                        <div className="detail-key-label">
                            <div className="detail-label">Bucket name</div>
                            <b>&nbsp;:&nbsp;</b>
                        </div>
                        <div className="detail-desc">{bucketData.bucket_name ? bucketData.bucket_name : "-"}</div>
                    </div>

                    <div className="key-value-pair">
                        <div className="detail-key-label">
                            <div className="detail-label">Bucket Uuid</div>
                            <b>&nbsp;:&nbsp;</b>
                        </div>
                        <div className="detail-desc">{bucketData.bucket_uuid ? bucketData.bucket_uuid : "-"}</div>
                        <div
                            className="copy-icon tooltip-div"
                            onClick={() => CopyText(bucketData.bucket_uuid, "TEXT")}
                        >
                            <Iconify icon="bitcoin-icons:copy-outline" />
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="key-value-pair mb-2">             
                            <div className="detail-key-label">
                                <div className="detail-label">Is Active</div>
                                <b>&nbsp;:&nbsp;</b>
                            </div>
                            <div className="detail-desc">{bucketData.is_active ? "True" : "False"}</div>           
                       
                    </div>
                    <div className="key-value-pair">
                        <div className="detail-key-label">
                            <div className="detail-label">Created Date</div>
                            <b>&nbsp;:&nbsp;</b>
                        </div>
                        <div className="detail-desc">{bucketData.created_at ? formatDate(bucketData.created_at,"MM/DD/YYYY") : "-"}</div>
                    </div>
                </div>
            </div>
        </div>

        <FinalMolGrid ref={molGridRef} configuration={bucketFileConfig}
            dataSource={bucketFileList}
            onActionChange={actionHandler}
            allowPagination={true}
            isLoading={isGetFilesByBucketIdLoading}
            pagination={{
                totalCount: totalRowCount,
                pageSize: 10,
                currentPage: 1,
            }}
            onPageChange={handlePageChange}
        />

    </>)
}
export default BucketManagementDetail;