import React, { useState } from "react";
import "./Sidebar.scss";
import Iconify from "../../../../components/ui/iconify/iconify";
import { AppIcons } from "../../../../data/appIcons";
import Image from "../../../../components/ui/image/Image";

import { Link } from "react-router-dom";
import { Menu } from "../menu/Menu";

const Sidebar = () => {
  const [navigationMenuList, setNavigationMenuList] = useState(Menu);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [clickedValueSubMenu, setClickedValueSubMenu] = useState(null);

  const handleMenuClick = (e, id) => {
    console.log(`Menu clicked: ${id}`);
    setSelectedMenu(selectedMenu === id ? null : id);
  };

  const handleChildClick = (e, id) => {
    e.preventDefault();
    console.log(`Submenu clicked: ${id}`);
    setClickedValueSubMenu(clickedValueSubMenu === id ? null : id);
  };

  return (
    <div className="sidebar-section">
      <div className="top-log-sec">
        <Image
          imgCustomClassName="order-icon"
          imagePath={AppIcons.logoImage}
          altText="Arrow Icon"
        />
      </div>
      <div className="menu-list-items">
        <ul>
          {navigationMenuList.map((group, groupIndex) => (
            <React.Fragment key={groupIndex}>
              <div className="group-label">{group.groupLabel}</div>
              {group.items.map((menuItem, itemIndex) => (
                <li key={itemIndex} className="menu-item">
                  <Link
                    to={menuItem.to}
                    className="menu-arrow"
                    onClick={(e) => handleMenuClick(e, menuItem.id)}
                  >
                    <Iconify icon={menuItem.iconClass} />
                    <span>{menuItem.name}</span>
                  </Link>
                  {/* Check for submenu */}
                  {/* {menuItem.subMenu && menuItem.children.length > 0 && (
                <ul className={`sidebar-dropdown ${selectedMenu === menuItem.id ? "show" : ""}`}>
                  {menuItem.children.map((subMenu, subIndex) => (
                    <li className="dropdown-menus" key={subIndex}>
                      <Link
                        to={subMenu.to}  
                        className={clickedValueSubMenu === subMenu.id ? "active-submenu" : ""}
                        onClick={(e) => handleChildClick(e, subMenu.id)}
                      >
                        {subMenu.submenuName}
                      </Link>
                    </li>
                  ))}
                </ul>
              )} */}
                </li>
              ))}
            </React.Fragment>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
