import { lazy } from "react";
import ApplicationManagement from "../../../Pages/applicationManagement/ApplicationManagement";
import BucketManagementDetail from "../../../Pages/bucketsManagement/features/BucketsManagementDetail";

// MOL GRID PAGES
const BasicMolGridPage = lazy(() => import("../../../Pages/MolGridPage/BasicGrid/BasicMolGridPage"));
// FORM CREATOR PAGES
const Dashboard = lazy(() => import("../../../Pages/Dashboard/Dashboard"));

const BucketsManagement = lazy(() => import("../../../Pages/bucketsManagement/BucketsManagement"));

export const ComponentNavigation = [
    {
        id: "",
        path: "/",
        exact: true,
        title: '',
        component: Dashboard,
        hasParams: false,
        text: 'Dashboard Page'
    },
    // {
    //     id: 'basicGrid',
    //     path: '/BasicGrid',
    //     exact: true,
    //     title: 'Basic Grid',
    //     component: BasicMolGridPage,
    //     hasParams: false,
    //     text: 'Basic Grid',
    // },
    {
        id: "applicationManagement",
        path: "/ApplicationManagement",
        exact: true,
        title: "Application Management",
        component: ApplicationManagement,
        text: "Application Management",
    },
    {
        id: "bucketsManagement",
        path: "/BucketsManagement",
        exact: true,
        title: "Buckets Management",
        component: BucketsManagement,
        text: "Buckets Management",
    },
    {
        id: "applicationManagement",
        path: "/ApplicationManagement/:id",
        exact: true,
        title: "Application Management",
        component: ApplicationManagement,
        text: "Application Management",
    },
    {
        id: "BucketsManagementDetail",
        path: "/BucketsManagementDetail/:id",
        exact: true,
        title: "Buckets Management Detail",
        component: BucketManagementDetail,
        text: "Buckets Management",
    },
];
