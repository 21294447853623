export const Menu = [
  {
    // groupLabel: "Administration ",
    items: [
      {
        name: "Dashboard",
        id: "Dashboard",
        to: "/",
        iconClass: "ri:home-3-line",
        subMenu: false,
        children: [],
      },
      // {
      //   name: "Application Management",
      //   id: "basicGrid",
      //   to: "/BasicGrid",
      //   iconClass: "solar:buildings-2-outline",
      //   subMenu: false,
      // },
      {
        name: "Application Management",
        id: "applicationManagement",
        to: "/ApplicationManagement",
        iconClass: "solar:buildings-2-outline",
        subMenu: false,
      },
      {
        name: "Buckets Management",
        id: "bucketsManagement",
        to: "/BucketsManagement",
        iconClass: "solar:buildings-2-outline",
        subMenu: false,
      },
    ],
  },
];
