/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useRef } from "react";
import CardSection from "../../components/ui/card/CardSection";
import SidebarModel from "../../components/ui/sidebarModel/SidebarModel";
import { AppIcons } from "../../data/appIcons";
import AddEditApplicationManagement from "./features/AddEditApplicationManagement";
import ApplicationManagementList from "./features/ApplicationManagementList";
import useDebounce from "../../app/customHook/useDebounce";
import { ApplicatonFormData } from "./config/ApplicationManagement.data";
import { onResetForm } from "../../utils/FormFields/ResetForm/handleResetForm";


const ApplicationManagement = () => {
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [applicationId, setApplicationId] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 300);
  const childRef = useRef();
  const [applicationData, setApplicationData] = useState(ApplicatonFormData)
  const handleToggleModal = () => {
  
    setIsEdit(false);
    onResetForm(applicationData, setApplicationData, ApplicatonFormData.initialState);
    setIsModelOpen(true);
    
  };

  const onSidebarClose = () => {
    
    setIsEdit(false);
    onResetForm(applicationData, setApplicationData, ApplicatonFormData.initialState);
    setIsModelOpen(false);
   
  };
  const handleEditClick = (data) => {
    setIsModelOpen(true);
    setIsEdit(true);
    setApplicationId(data.application_id);
  };
  const onSuccess = () => {
    if (childRef.current) {
      childRef.current.callChildFunction();
    }
  };

    const handleChange = (event) => {
      const value = event.target.value;
       setSearch(value);
     
    };

  return (
    <div>
      <CardSection
        cardTitle="Application Management"
        buttonClassName="btn theme-button"
        rightButton={true}
        buttonText="Add Application"
        textWithIcon={true}
        iconImg={AppIcons.PlusIcon}
        titleButtonClick={handleToggleModal}
        searchbuttonText="Search"
        searchButton={true}
        searchInputName="Search By Application"
        searchInput={true}
        handleChange={handleChange}
      >
        <ApplicationManagementList
          childRef={childRef}
          search={debouncedSearch}

          handleEditClick={handleEditClick}
        />
      </CardSection>
      <SidebarModel
        modalTitle={`${isEdit ? "Update Application" : "Add Application"}`}
        contentClass="content-35"
        onClose={onSidebarClose}
        //  modalTitleIcon={AppIcons.PlusIcon}
        isOpen={isModelOpen}
      >
        <AddEditApplicationManagement
          isEdit={isEdit}
          onSuccess={onSuccess}
          isModelOpen={isModelOpen}
          setApplicationData={setApplicationData}
          applicationData={applicationData}
          applicationId={applicationId}
          onClose={onSidebarClose}
        />
      </SidebarModel>
    </div>
  );
};
export default ApplicationManagement;
