import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { getAuthProps, setTokenProps } from "../../lib/authenticationLibrary";
const { Mutex } = require('async-mutex');

const mutex = new Mutex();
// const IsProdMode = false;
const IsProdMode = true;
const API_URL = (IsProdMode) ? process.env.REACT_APP_TESTSITEURL : process.env.REACT_APP_LOCALURLS


const baseQuery = fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => {
        headers.set("Content-Type", "application/json");
        return headers;
    }
});


export const defaultBaseQuery = fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => {
        headers.set("Content-Type", "application/json");
        let authData = getAuthProps();
        if (authData) {
            const token = authData.token.token;
            headers.set("Authorization", `Bearer ${token}`)
        }
        return headers;
    }
});

export const customFetchBase = async (args, api, extraOptions) => {
    const authData = getAuthProps();
    let token = null;
    if (authData) {
        token = authData.token.access_token;

        //** Re-generate the token. expire in 5 minutes */
        const now = Math.floor(new Date().getTime() / 1000);
        const fiveMinutesInSeconds = 300;
        let { expires_at } = authData.token;
        expires_at = expires_at.endsWith('Z') ? expires_at : expires_at + 'Z';
        const expiryDate = Math.floor(new Date(expires_at).getTime() / 1000);
        if (expiryDate && expiryDate - now < fiveMinutesInSeconds) {
            await refreshToken();
        }
    }

    if (!token) {
        window.location.href = '/login';
        return;
    }

    await mutex.waitForUnlock();

    args.headers = {
        ...args.headers,
        Authorization: `Bearer ${token}`,
    };

    let result = await baseQuery(args, api, extraOptions);

    if (result.error) {
        if (result.error.status === 401) {
            window.location.href = '/login';
        }
    }
    return result;
}

const refreshToken = async () => {
    try {
        const access_token = getAuthProps()?.token?.access_token;
        if (!access_token) {
            console.log("No refresh token found. Redirecting to login...");
            window.location.href = '/login';
            return;
        }
        const response = await fetch(API_URL + '/token/refreshToken/', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${access_token}`,
            },
        });

        if (!response.ok) {
            window.location.href = '/login';
            return;
        }

        const responseData = await response.json();
        if (responseData?.data && responseData?.data.access_token) {
            let token = {
                access_token: responseData?.data.access_token,
                expires_at: responseData?.data.expires_at
            }
            setTokenProps(token);
        } else {
            window.location.href = '/login';
        }
    } catch (error) {
        window.location.href = '/login';
    }
};