/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "./Header.scss";
import Avatar from "../../../../components/ui/avatar/Avtar";
import SearchBar from "../../../../components/ui/searchBar/SearchBar";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../../app/slice/authSlice";
import { Link } from "react-router-dom";

const Header = () => {

  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);

  const LogoutButton = () => {
    dispatch(logout());
  };

  const handleChange = () => { };

  return (
    <>
      <div className={`header`}>
        {/* End of Header Menu Items */}
        <div className="left-section">
          <div className="search-bar">
            <SearchBar searchText="Search..." handleChange={handleChange} searchValue="" />
          </div>
        </div>
        <div className="right-section">
          <div className="profile-section">
            <div className="profiles">
              <div className="profile-img">
                <Avatar
                  src=""
                  alt={authState?.user?.full_name}
                  size="small"
                  shape="circle"
                />
                <div className="d-flex flex-column">
                  <span className="profile-name">{authState?.user?.full_name}</span>
                </div>
              </div>
              <div className="profile-dropdown-menu">
                <div className="title-list profile-title">
                  <div className="user-icon">
                    <Avatar
                      src=""
                      alt={authState?.user?.user_name}
                      size="small"
                      shape="circle"
                    />
                  </div>
                  <div className="dropdown-user-info">
                    <span className="name-title ">Signed in as</span>
                    <span className="user-name">{authState?.user?.user_name}</span>
                  </div>
                </div>
                <div className="title-list drop-down-icon-menu">
                  <Link to=''>
                    <span className="bi bi-gear">Setting</span>
                  </Link>
                  <Link to=''>
                    <span className="bi bi-pencil">Edit Profile</span>
                  </Link>
                </div>
                <div className="title-list drop-down-icon-menu" onClick={LogoutButton}>
                    <span className="bi bi-box-arrow-left">Log out</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
